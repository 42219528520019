var testMap = new Map();
if (testMap.set(1, 2) !== testMap) {
    var set_1 = testMap.set;
    Map.prototype.set = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        set_1.apply(this, args);
        return this;
    };
}
var testSet = new Set();
if (testSet.add(3) !== testSet) {
    var add_1 = testSet.add;
    Set.prototype.add = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        add_1.apply(this, args);
        return this;
    };
}
var frozen = {};
if (typeof Object.freeze === 'function') {
    Object.freeze(frozen);
}
try {
    testMap.set(frozen, frozen).delete(frozen);
}
catch (_a) {
    var wrap = function (method) {
        return method && (function (obj) {
            try {
                testMap.set(obj, obj).delete(obj);
            }
            finally {
                return method.call(Object, obj);
            }
        });
    };
    Object.freeze = wrap(Object.freeze);
    Object.seal = wrap(Object.seal);
    Object.preventExtensions = wrap(Object.preventExtensions);
}
